import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';

function Copyright() {
  const [year, setYear] = useState();

  useEffect(() => {
    const Year = new Date().getFullYear();
    setYear(Year);
  }, []);

  return (
    <div>
      <Grid container alignItems='center' justifyContent='center' textAlign='center'>
        <Typography sx={ {typography: {md: 'body1'}, color: 'primary.footer', pt: 1} } >
          © {year} Linktax <br />
        </Typography>
      </Grid>
      <Grid container alignItems='center' justifyContent='center' textAlign='center'>
        <Typography sx={{ typography: { md: 'caption' }, color: 'primary.footer', pb:1 }}>
          Developed by{' '}
          <a href='https://geekinate.com/' target='_blank' rel="noreferrer" style={{ textDecoration: 'none', cursor: 'pointer', color: '#888a8c' }}>
            Geekinate
          </a>
        </Typography>
      </Grid>
    </div>
  );
}

export default Copyright;
