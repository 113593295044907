import React, { useState } from 'react';
import { AppBar, Box, Container, Toolbar, IconButton, Divider, Drawer, List, ListItem, ListItemText, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { theme } from '../../theme';
import { Navlogo, Navlinks } from '../../Data';

function Navbar() {
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = (state) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(state);
  };
  const handleLinkClick = (e, link) => {
    e.preventDefault();
    const linkPath = document.querySelector(link);
    linkPath
      ? window.scrollTo({
          top: linkPath.offsetTop - 70,
          behavior: 'smooth',
        })
      : window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AppBar sx={{boxShadow: 1}}>
      <Container>
        <Toolbar
          sx={{justifyContent: 'space-between'}}
        >
          <img src={Navlogo} alt='navbar logo' width='130px' height='auto' />
          <IconButton
            size='large'
            edge='start'
            aria-label='menu'
            onClick={toggleDrawer(true)}
            sx={{
              ml: 'auto',
              display: { md: 'none', sm: 'block' },
            }}
            id='menu_btn'
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor={'right'} open={drawer} onClose={toggleDrawer(false)}>
            <Box
              role='presentation'
              onKeyDown={toggleDrawer(false)}
              sx={{
                color: 'ternary.main',
                height: '100%',
                width: { sm: 200, xs: 200 },
              }}
            >
              <List
                sx={{
                  px: 1,
                }}
              >
                {Navlinks.map((item, index) => (
                  <div onClick={toggleDrawer(false)} key={index}>
                    <ListItem
                      button
                      key={index}
                      sx={{
                        ':hover': {
                          color: 'secondary.main',
                        },
                      }}
                    >
                      <ListItemText onClick={(e) => handleLinkClick(e, item.link)}>{item.text}</ListItemText>
                    </ListItem>
                  </div>
                ))}
                <Divider sx={{ borderColor: 'secondary.main' }} />
                <ListItem
                  button
                  sx={{
                    ':hover': {
                      color: 'secondary.main',
                    },
                  }}
                >
                  <ListItemText>Login</ListItemText>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Box sx={{ display: { md: 'block', sm: 'none', xs: 'none' }, mr: 2 }}>
            <ul>
              {Navlinks.map((link, index) => (
                <Link
                  component='button'
                  color={theme.palette.ternary.main}
                  sx={{
                    typography: {
                      md: 'subtitle1',
                      xs: 'subtitle2',
                    },
                    mx: { md: 2, xs: 1 },
                    textAlign: 'center',
                    ':hover': {
                      color: 'secondary.main',
                    },
                  }}
                  underline='none'
                  key={index}
                  onClick={(e) => handleLinkClick(e, link.link)}
                >
                  {link.text}
                </Link>
              ))}
            </ul>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
