import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0000000',
      second: '#ffffff',
      footer: '#888a8c',
      copyright: '#4a4b4d',
    },
    secondary: {
      main: '#64C7EA',
      box: '#F9F9FF',
    },
    ternary: {
      main: '#2D3984',
    },
    text: {
      main: '#878584',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  overrides: {
    MuiInputBase: {
      input: {
        backgroundColor: 'ternary.input',
        '&  .MuiFormHelperText-root.Mui-error': {
          backgroundColor: '#F9F9FF',
          margin: 0,
        },
      },
    },
  },
});
