import { ThemeProvider } from "@mui/system";
import { theme } from "./theme";
import Navbar from "./Components/Navbar";
import LandingPage from "./Components/LandingPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <LandingPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
