import React from "react";
import { Grid, Container, Typography } from "@mui/material";
import { Slide } from "react-awesome-reveal";
import { ServicesData as Data, ServiceSection, BusinessData } from "../../Data";
import ServicesCard from "./ServicesCard";
import "./Triangle.css";

function Services() {
  return (
    <div id="services">
      <Slide triggerOnce direction="up">
        <Container sx={{ py: 10 }}>
          <Grid container direction="column" textAlign="center" alignItems="center" sx={{ pb: 10 }}>
            <Grid item>
              <Typography sx={{ typography: { md: "h3", xs: "h4" }, mb: 2 }}>{ServiceSection.heading}</Typography>
              <Typography
                textAlign="center"
                sx={{
                  typography: { md: "body1", xs: "body2" },
                  color: "text.main",
                }}
              >
                {ServiceSection.paragraph}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ typography: { md: "h4", xs: "h5" }, textAlign: "center", mb: { md: 6, xs: 4 } }}>Core Services</Typography>
          <Grid container sx={{ pb: 5 }}>
            {Data.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ServicesCard data={item} />
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ typography: { md: "h4", xs: "h5" }, textAlign: "center", mb: { md: 6, xs: 4 } }}>Business Support Services</Typography>
          <Grid container>
            {BusinessData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ServicesCard data={item} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Slide>
      <div className="maindiv">
        <div className="triangle"></div>
        <div className="rect"></div>
      </div>
    </div>
  );
}

export default Services;
