import { makeStyles } from "@mui/styles";
import { theme } from "../../theme";

export const LandingPageStyles = makeStyles({
  review_sec: {
    background: theme.palette.ternary.main,
  },
  footerlogo: {
    width: "170px",
    height: "50px",
    marginLeft: "16px",
    marginTop: "25px",
  },
  contact_container: {
    background: "url('./imgs/body12.png') no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "right",
    width: "100%",
  },
  review_card: {
    background: "url('./imgs/body11.png') no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
  },
  copyright: {
    background: theme.palette.primary.copyright,
  },
});
