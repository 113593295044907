import React from 'react';
import { Grid, Container, Typography, Button } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { MainSection as Data } from '../../Data';
import { theme } from '../../theme';

function MainSec() {
  const handleClick = (e, link) => {
    const linkPath = document.querySelector(link);
    linkPath
      ? window.scrollTo({
          top: linkPath.offsetTop - 40,
          behavior: 'smooth',
        })
      : window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container id='home'>
      <Grid container direction='row' spacing={4} sx={{ my: 1 }}>
        <Grid item xs={12} md={6} sx={{ my: { md: 11, xs: 5 } }}>
          <Slide triggerOnce direction='up'>
            <Typography
              sx={{
                mt: { md: 7, xs: 2 },
                typography: { md: 'h3', xs: 'h4' },
              }}
            >
              {Data.heading}
            </Typography>
            <Typography
              sx={{
                mt: { md: 3, xs: 2 },
                fontSize: '17px',
                color: 'text.main',
              }}
            >
              {Data.paragraph}
            </Typography>
            <Button
              sx={{
                mt: { md: 5, xs: 2 },
                color: 'ternary.main',
                border: '1px solid',
                ':hover': {
                  background: theme.palette.ternary.main,
                  color: 'primary.main',
                },
              }}
              onClick={(e) => handleClick(e, '#contact')}
            >
              {Data.button}
            </Button>
          </Slide>
        </Grid>

        <Grid item xs={12} md={6} sx={{ my: { md: 12, xs: 5 } }}>
          <Slide triggerOnce direction='up'>
            <img src={Data.img} alt='mainsec' width='100%' height='auto' />
          </Slide>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MainSec;
