const Navlogo = './imgs/link_tax.png';
const Navlinks = [
  { text: 'Home', link: '#home' },
  { text: 'Services', link: '#services' },
  { text: 'About us', link: '#aboutus' },
  { text: 'Team', link: '#team' },
  { text: 'Reviews', link: '#reviews' },
  { text: 'Contact', link: '#contact' },
];
const MainSection = {
  heading: 'Quality Tax Consultant to help you reach your next Goals',
  paragraph: 'The combination of your business knowledge and our support services will help your business to grow and increase profitability',
  button: 'Book a callBack',
  img: './imgs/Mainsec.jpg',
};
const ServiceSection = {
  heading: 'We understand your needs',
  paragraph: 'We provide a range of compliance services to support all aspects of business administration',
};
const BusinessData = [
  {
    heading: 'Dissolution',
    detail:
      'Linktax can help you if you need to close your company for any reason, then a formal dissolution process needs to be followed, providing it is solvent.',
    image: './imgs/dissolution.png',
  },
  {
    heading: 'Insolvency',
    detail: 'If you need to close or liquidate your company and it is insolvent, then a formal qualified process needs to be followed.',
    image: './imgs/Insolve.png',
  },
  {
    heading: 'Scorecards',
    detail: 'A balanced scorecard is a strategic management performance metric used to identify and improve various internal business functions.',
    image: './imgs/Balance.png',
  },
  {
    heading: "KPI's",
    detail:
      'A Key Performance Indicator (KPI) is a measure that is used to evaluate the performance of your company against its strategic objectives. In essence, they are the vital signs of your company. KPI’s are normally a part of a Balanced Scorecard.',
    image: './imgs/performance.png',
  },
  {
    heading: 'VAT',
    detail:
      'Registering for and administering VAT is time consuming and the penalties for getting it wrong or late filing can pinch hard. We can do the heavy lifting for you so you concentrate your time and energy on what you do best.',
    image: './imgs/VAT.png',
  },
  {
    heading: 'Budgeting',
    detail:
      'Creating a budget and rolling forecasts is the only way you can align your business strategy with the numbers. It’s part of the foresight and targets your business is aiming to achieve and unless you quantify it with clarity, your chances of achieving it are severely diminished.',
    image: './imgs/Budget.png',
  },
];
const ServicesData = [
  {
    heading: 'Tax Returns',
    detail: 'Eliminate the confusion and hard work in completing your self-assessment tax return and leave it to us.',
    image: './imgs/tax-returns.png',
  },
  {
    heading: 'Grants Claim',
    detail: 'We help you to claim grants for your business so your company will have exponential growth and high revenue generation ',
    image: './imgs/grant.png',
  },
  {
    heading: 'Corporate',
    detail: 'Linktax reduces your paperwork and admin burden, so you can concentrate your energy on growth.',
    image: './imgs/corporate.png',
  },
  {
    heading: 'Payroll',
    detail: 'We handle your Administering payroll that involves accurate, up to date knowledge of a whole range of issues, benefits and allowances.',
    image: './imgs/payroll.png',
  },
  {
    heading: 'Accounts',
    detail:
      "We're specialised in accounting for business owners who want an all-in-one accounting service that allows them to concentrate on running their businesses",
    image: './imgs/Accounts.png',
  },
  {
    heading: 'Book keeping',
    detail: 'We offer a complete book-keeping service, saving you the expense and commitment of employing a book-keeper yourself',
    image: './imgs/bookkeeping.png',
  },
];
const teamMembers = [
  {
    name: 'Muhammad Zamir',
    description: 'Managing Director',
    photo: './imgs/team/Muhammad-Zamir.jpg',
  },
  {
    name: 'Muhammad Atif',
    description: 'Payroll Manager',
    photo: './imgs/team/Muhammad-Atif.jpeg',
  },
  {
    name: 'Muhammad Haseeb',
    description: 'Admin Assistant',
    photo: './imgs/team/Muhammad-Haseeb.jpeg',
  },
  {
    name: 'Muhammad Suleman Ahmed',
    description: 'Executive Assistant',
    photo: './imgs/team/Muhammad-Suleman.jpg',
  },

  {
    name: 'Tayyab Nasir',
    description: 'Manager Accounts',
    photo: './imgs/team/Tayyab-Nasir.jpg',
  },
  {
    name: 'Ali Raza',
    description: 'Accounts Manager',
    photo: './imgs/team/Ali-Raza.jpg',
  },
  {
    name: 'Zain Shahid',
    description: 'Assistant Accountant',
    photo: './imgs/team/Zain-Shahid.jpeg',
  },
  {
    name: 'Sohail Kazmi',
    description: 'Office Attendant',
    photo: './imgs/team/Sohail-Kazmi.jpg',
  },
  {
    name: 'Muhammad Danish',
    description: 'Chauffer',
    photo: './imgs/team/Muhammad-Danish.jpg',
  },
];
const AboutusData = {
  paragraph:
    "We specialise in helping businesses, whether big or small, to achieve their goals. Through our own professional expertise and by working directly with organisations, we've developed a robust understanding of the factors that govern bussiness growth. Our objective is to help our clients maximise their potential. Our team of experts make sure that they give more value to your money.",
  img: './imgs/10586.png',
};

const FooterData = {
  logo: './imgs/link_tax_footer.png',
};
const ReviewData = [
  {
    Img: './imgs/dummy.jpg',
    Name: 'Aabans Videos',
    Review:
      'I have been using this accountancy firm for a number of years since I have been self employed and I can not thank them enough for all the advice and work they have done for me. They have a really professional approach with how they conduct themselves and when called upon their services they have never failed to answer any questions I have had.',
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Mousin Javed',
    Review:
      'Been with Linktax for the past 4 years, amazing professional service offered for my business needs. Can always count on Tayyab 24/7 to provide a top class, prompt and impeccable service to suit my needs! 👍.',
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Shak Win',
    Review:
      'Very good professional company. Honest advice and support when you need it. These guys do not clock watch like many other firms. Would give them 10 stars 🌟 if I could!',
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Fahad Mughal',
    Review: 'Professional and responsive! Highly recommended. Brilliant service from everyone at Linktax 👍.',
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Abid Ashraf',
    Review: 'Fantastic and really easy with explaining topics and the different ways in respects of accounts. Absolutely 100% recommended.',
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Mohammed Khawit',
    Review:
      "As a new business owner, I didn't know where to start when it came to the accounting and tax aspect of my business. Tayyab was excellent, he recommended the best structure for my business and explained the reasons why in a way which I could easily understand. Thanks.",
  },
  {
    Img: './imgs/dummy.jpg',
    Name: 'Sal K',
    Review: 'Very professional. Highly recommended from the simple booking keeping tasks to medium and large scale businesses like myself!',
  },
];
export { Navlogo, Navlinks, MainSection, ServiceSection, ServicesData, teamMembers, AboutusData, FooterData, ReviewData, BusinessData };
