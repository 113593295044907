import React from "react";
import { Container } from "@mui/material";
import { LandingPageStyles } from "./LandingPageStyles";
import MainSec from "../MainSection";
import Services from "../Services";
import Aboutus from "../Aboutus";
import OurTeam from "../OurTeam";
import Reviews from "../Reviews";
import ContactUs from "../ContactUs";
import Footer from "../Footer";
import Copyright from "../Copyright";

function LandingPage() {
  const classes = LandingPageStyles();
  const { contact_container, review_sec, copyright, review_card } = classes;
  return (
    <div>
      <Container>
        <MainSec />
        <Services />
      </Container>

      <div className={review_sec}>
        <Aboutus />
      </div>
      <div className={review_card}>
        <Container>
          <OurTeam />
          <Reviews />
        </Container>
      </div>
      <div className={contact_container}>
        <ContactUs />
      </div>
      <div className={review_sec}>
        <Container>
          <Footer />
        </Container>
      </div>
      <div className={copyright}>
        <Copyright />
      </div>
    </div>
  );
}

export default LandingPage;
