import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import Avatar from '@mui/material/Avatar';

function ReviewCard({ data }) {
  return (
    <Container>
      <Grid container direction='column' sx={{ px: { md: 15, xs: 3 } }} alignContent='center' alignItems='center' textAlign='center'>
        <span style={{ fontSize: '70px', marginRight: '100%', fontFamily: 'sans-serif', color: '#66C8EA' }}>❝</span>
        <Typography sx={{ typography: { md: 'body1', xs: 'body2' }, mb: 1, px: 3 }}>
          {data?.Review}
          <br />
          <br />

          <b>{data?.Name}</b>
          <br />
        </Typography>
        <span style={{ fontSize: '80px', marginLeft: '100%', fontFamily: 'sans-serif', color: '#66C8EA' }}>❞</span>
        <Avatar alt='customer' src={data?.Img} sx={{ width: 100, height: 100, mt: 2, border: '3px solid #65C7EA ' }} />
      </Grid>
    </Container>
  );
}

export default ReviewCard;
