import React from 'react';
import { Typography, Grid, TextField, Container, IconButton, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { Slide } from 'react-awesome-reveal';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { theme } from '../../theme';
import { ReactComponent as Contactimg } from './contact.svg';

function ContactUs() {
  const Schema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    message: Yup.string().required('Message is required'),
    subject: Yup.string().required('Subject is required'),
    name: Yup.string().required('Name is required'),
  });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const handleOpen = (newState) => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const action = (
    <>
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      message: '',
      email: '',
      subject: '',
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const storeValues = JSON.stringify(values);
      const config = {
        method: 'post',
        url: '/api/email',
        headers: {
          'Content-Type': 'application/json',
        },
        data: storeValues,
      };
      axios(config)
        .then((response) => {
          setSubmitting(false);
          resetForm({ values: '' });
        })
        .catch(() => {
          handleOpen({
            vertical: 'top',
            horizontal: 'center',
          });
          setSubmitting(false);
        });
    },
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Container id='contact'>
      <Grid container direction='column' alignItems='center' justifyContent='space-around' sx={{ py: 5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message='Please try again'
          key={vertical + horizontal}
          action={action}
        />
        <Typography
          sx={{
            mt: { md: 12, sm: 6, xs: 4 },
            mb: { md: 5, xs: 4 },
            typography: { md: 'h3', xs: 'h4' },
          }}
        >
          Contact Us
        </Typography>

        <Grid
          component='div'
          container
          direction='row'
          textAlign='center'
          sx={{
            px: { md: 6, sm: 4, xs: 0 },
            py: { md: 3, xs: 3 },
            pb: { md: 15, xs: 5 },
          }}
        >
          <Grid item sm={6} xs={12} sx={{ my: 1 }}>
            <Slide triggerOnce direction='up'>
              <Contactimg />
            </Slide>
          </Grid>
          <Grid item sm={6} xs={12} sx={{ p: 4, backgroundColor: 'secondary.box', borderRadius: 5 }}>
            <Slide triggerOnce direction='up'>
              <FormikProvider value={formik}>
                <Form autoComplete='off' noValidate>
                  <Grid container item direction='row' justifyContent='space-between'>
                    <Grid xs={6} container item direction='column' alignItems='start' sx={{ pr: 2, pb: 3 }}>
                      <TextField
                        fullWidth
                        label='Full Name'
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid xs={6} container item direction='column' alignItems='start'>
                      <TextField
                        fullWidth
                        label='Email'
                        type='email'
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction='column' alignItems='start' sx={{ pb: 3 }}>
                    <TextField
                      label='Subject'
                      fullWidth
                      type='text'
                      {...getFieldProps('subject')}
                      error={Boolean(touched.subject && errors.subject)}
                      helperText={touched.subject && errors.subject}
                    />
                  </Grid>
                  <Grid container item direction='column' alignItems='start'>
                    <TextField
                      fullWidth
                      multiline
                      label='Message'
                      rows={2}
                      type='text'
                      {...getFieldProps('message')}
                      error={Boolean(touched.message && errors.message)}
                      helperText={touched.message && errors.message}
                    />
                  </Grid>

                  <LoadingButton
                    fullWidth
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    type='submit'
                    sx={{
                      mt: { md: 3, xs: 2 },
                      color: 'ternary.main',
                      border: '2px solid',
                      ':hover': {
                        background: theme.palette.ternary.main,
                        color: 'primary.second',
                      },
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Form>
              </FormikProvider>
            </Slide>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactUs;
