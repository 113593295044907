import React from 'react';
import { Link, Typography, Grid, Container, Stack, List, ListItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FooterData as Data } from '../../Data';
import { LandingPageStyles } from '../LandingPage/LandingPageStyles';

function Footer() {
  const classes = LandingPageStyles();
  const { footerlogo } = classes;
  return (
    <Container>
      <Grid container direction='row' sx={{ py: 10, px: 3 }} spacing={4}>
        <Grid item md={4} xs={12} >
          <img src={Data.logo} alt='footer' className={footerlogo} />
          <List sx={{ color: '#ffff' }}>
            <ListItem>
              <Link underline='none' sx={{ fontSize: 18, cursor: 'pointer' }} to='#'>
                Home
              </Link>
            </ListItem>
            <ListItem>
              <Link underline='none' sx={{ fontSize: 18, cursor: 'pointer' }} to='#'>
                Services
              </Link>
            </ListItem>
            <ListItem>
              <Link underline='none' sx={{ fontSize: 18, cursor: 'pointer' }} to='#'>
                Careers
              </Link>
            </ListItem>
          </List>
          <Stack direction='row' sx={{ px: 1 }}>
            <a href='https://www.facebook.com/linktax.co.uk/' target='__blank'>
              <FacebookOutlinedIcon sx={{ px: 0.5, color: '#ffff', fontSize: 22, cursor: 'pointer' }} />
            </a>
            <a href='https://www.linkedin.com/company/linktaxglobal/about/' target='__blank'>
              <LinkedInIcon sx={{ px: 0.5, color: '#ffff', fontSize: 22, cursor: 'pointer' }} />
            </a>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              typography: {
                md: 'h4',
              },
              color: 'primary.second',
              mt: 4,
            }}
          >
            London Office
          </Typography>

          <Stack direction='row'>
            <HomeIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              11-A, Snow Hill, Shelto Stoke-on-Trent Staffordshire ST1 4LU United Kingdom
            </Typography>
          </Stack>
          <Stack direction='row'>
            <EmailIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              stoke@linktax.co.uk
            </Typography>
          </Stack>
          <Stack direction='row'>
            <LocalPhoneIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              +441782 212 828
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              typography: {
                md: 'h4',
              },
              color: 'primary.second',
              mt: 4,
            }}
          >
            Islamabad Office
          </Typography>

          <Stack direction='row'>
            <HomeIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              11-A, Ahmad Center, 2nd Floor I-8/2, Islamabad, Pakistan
            </Typography>
          </Stack>
          <Stack direction='row'>
            <EmailIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              isb@linktax.co.uk
            </Typography>
          </Stack>
          <Stack direction='row'>
            <LocalPhoneIcon sx={{ color: '#ffff', mt: 3, px: 0.5, fontSize: 20 }} />
            <Typography
              sx={{
                typography: {
                  md: 'body1',
                  xs: 'caption',
                },
                color: 'primary.second',
                mt: 3,
              }}
            >
              +92 51 8485040-2
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
