import React from 'react';
import { Typography, Grid, Card } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { teamMembers as Data } from '../../Data';

function OurTeam() {
  return (
    <div id='team'>
      <Slide triggerOnce direction='up'>
        <Grid component='section' container direction='column' alignItems='center'>
          <Typography
            sx={{
              mt: { md: 13, sm: 3, xs: 5 },
              mb: { md: 10, xs: 5 },
              typography: { md: 'h3', xs: 'h4' },
            }}
          >
            Our Team
          </Typography>
          <Grid component='div' container item direction='row' alignItems='center' justifyContent='center' sx={{ pb: { xs: 5, md: 15 }, px: 4 }}>
            
            {Data.map((person, index) => (
              <Grid md={4} sm={6} xs={12} container item key={index} justifyContent='center' direction='column' textAlign='center' alignItems='center'>
                <Card key={index} sx={{ mb: 8, borderRadius: 5, borderBottom: '5px solid #65C7EA',  }}>
                <img style={{ width: '270px', height: '270px', objectFit: 'cover', objectPosition: 'top' }} src={person.photo} alt={person.name}/>
                  <Typography
                    sx={{
                      mt: 1,
                      typography: 'body1',
                      color: '#20b5e8',
                    }}
                  >
                    <b>{person.name}</b>
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      pb: 2,
                    }}
                  >
                    {person.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Slide>
    </div>
  );
}

export default OurTeam;
