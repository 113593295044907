import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { AboutusData as Data } from '../../Data';
import { ReactComponent as Aboutimg } from './about.svg';

function Aboutus() {
  return (
    <div id='aboutus'>
      <Container sx={{ px: { md: 5.8, xs: 2 }, py: { md: 13, xs: 5 } }}>
        <Typography
          sx={{
            mb: { md: 3, xs: 2 },
            mx: 0,
            typography: { md: 'h3', xs: 'h4' },
            color: 'primary.second',
            textAlign: 'center',
          }}
        >
          About Us
        </Typography>
        <Grid component='section' container alignItems='center' justifyContent='space-around' spacing={2}>
          <Grid item xs={12} sm={6}>
            <Slide triggerOnce direction='up'>
              <Typography
                sx={{
                  my: { md: 2, xs: 1 },
                  typography: { md: 'h6', xs: 'body2' },
                  color: 'primary.second',
                  textAlign: 'justify',
                }}
              >
                {Data.paragraph}
              </Typography>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mt: 3, maxWidth: { md: '550px', xs: '380px' } }}>
            <Slide triggerOnce direction='up'>
              <Aboutimg />
            </Slide>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Aboutus;
