import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { Slide } from 'react-awesome-reveal';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ReviewStyles } from './ReviewStyles';
import { ReviewData as Data } from '../../Data';
import ReviewCard from './ReviewCard';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Reviews() {
  const classes = ReviewStyles();
  const { buttonclass } = classes;
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Data.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const theme = useTheme();
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Grid container alignItems='center' justifyContent='center' direction='column' id='reviews'>
      <Typography
        sx={{
          typography: { md: 'h3', xs: 'h5' },
          pt: 13,
        }}
      >
        What our customer says
      </Typography>
      <Grid
        container
        sx={{
          px: { md: 5, xs: 3.9 },
          py: { md: 3, xs: 15 },
        }}
        spacing={4}
      >
        <Slide triggerOnce direction='up'>
          <Box sx={{ maxWidth: { lg: '100%', md: '1050px', sm: '700px', xs: '350px' } }}>
            <Grid container textAlign='justify' alignItems='center' justifyContent='center'>
              <Container sx={{ pb: { md: 10, xs: 2 } }}>
                <AutoPlaySwipeableViews
                  interval={7000}
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {Data.map((data, index) => (
                    <ReviewCard data={data} key={index}/>
                  ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position='static'
                  variant='dots'
                  sx={{
                    '.css-114p2tk-MuiMobileStepper-dot': {
                      backgroundColor: '#9da5a8',
                    },
                    '.MuiMobileStepper-dotActive': {
                      backgroundColor: '#65C7EA',
                    },
                    background: 'transparent',
                    px: { md: 41 },
                  }}
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size='medium'
                      className={buttonclass}
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                      sx={{ color: '#818285' }}
                    >
                      {activeStep === maxSteps - 1 ? (
                        <Avatar alt='customer' src='./imgs/dummy.jpg' sx={{ width: 50, height: 50, mt: 2 }} />
                      ) : (
                        <Avatar alt='customer' src={Data[activeStep + 1]?.Img} sx={{ width: 50, height: 50, mt: 2 }} />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button size='medium' className={buttonclass} onClick={handleBack} disabled={activeStep === 0} sx={{ color: '#818285' }}>
                      {activeStep === 0 ? (
                        <Avatar alt='customer' src='./imgs/dummy.jpg' sx={{ width: 50, height: 50, mt: 2 }} />
                      ) : (
                        <Avatar alt='customer' src={Data[activeStep - 1]?.Img} sx={{ width: 50, height: 50, mt: 2 }} />
                      )}
                    </Button>
                  }
                />
              </Container>
            </Grid>
          </Box>
        </Slide>
      </Grid>
    </Grid>
  );
}

export default Reviews;
