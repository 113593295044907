import { makeStyles } from "@mui/styles";
import { theme } from "../../theme";

export const ReviewStyles = makeStyles({
  ReviewSec: {
    background: theme.palette.ternary.main,
  },
  buttonclass: {
    position: "abolute",
    bottom: 100,
  },
});
